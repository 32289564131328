import React, { useEffect, useState, useCallback } from 'react';
import {
  Paper,
  Stack,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Button,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
} from '@mui/material';
import capitalize from 'lodash/capitalize';
import { debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { grades } from '../../../constants/grades';

const sortByString = (a, b, property) => a[property]?.localeCompare(b[property]);

const sortObservations = (observations, property, order) => {
  if (!observations) return [];

  const observationsCopy = [...observations];
  let compareFunction;

  switch (property) {
    case 'observation_date':
      compareFunction = (a, b) => new Date(a.observation_date) - new Date(b.observation_date);
      break;
    case 'observable':
      compareFunction = (a, b) => a.observable?.name?.localeCompare(b.observable?.name);
      break;
    case 'school_name':
      compareFunction = (a, b) => a.school_name?.name?.localeCompare(b.school_name?.name);
      break;
    case 'survey_name':
    case 'status': {
      compareFunction = (a, b) => sortByString(a, b, property);
      break;
    }
    case 'grade_level': {
      compareFunction = (a, b) => grades.indexOf(a.grade_level) - grades.indexOf(b.grade_level);
      break;
    }
    default:
      compareFunction = () => 0;
  }
  return observationsCopy.sort((a, b) => {
    let comparison = compareFunction(a, b);
    return order === 'asc' ? comparison : -comparison;
  });
};

const SortableTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: 'observation_date',
      numeric: false,
      label: 'Date',
      sortable: true,
      sx: { width: '120px' },
    },
    { id: 'school_name', numeric: false, label: 'School', sortable: true },
    { id: 'observable', numeric: false, label: 'Teacher', sortable: true },
    { id: 'grade_level', numeric: false, label: 'Grade', sortable: true, sx: { width: '120px' } },
    {
      id: props.survey ? 'observer' : 'survey_name',
      numeric: false,
      label: props.survey ? 'Observer' : 'Survey',
      sortable: true,
    },
    { id: 'status', numeric: false, label: 'Status', sortable: true },
    { id: 'actions', numeric: false, label: 'Actions', sortable: false, sx: { width: '100px' } },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            {...(headCell.sx && { sx: headCell.sx })}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const DataRow = ({ survey, observation, onEditObservation, onDeleteObservation }) => {
  return (
    <TableRow>
      <TableCell align="left">
        {observation.observation_date && new Date(observation.observation_date).toLocaleDateString({}, { timeZone: 'UTC' })}
      </TableCell>
      <TableCell>{observation?.school_name?.name}</TableCell>
      <TableCell>{observation?.observable?.name}</TableCell>
      <TableCell>{observation.grade_level}</TableCell>
      <TableCell align="left">{survey ? observation?.observer?.full_name : observation?.survey_name}</TableCell>
      <TableCell>{capitalize(observation.status)}</TableCell>
      <TableCell>
        <Stack direction="row" spacing={1} alignItems="center">
          {typeof onDeleteObservation === 'function' && (
            <Tooltip title="Delete">
              <IconButton aria-label="Delete" size="small" onClick={() => onDeleteObservation(observation.survey_id, observation.id)}>
                <FontAwesomeIcon icon={faTrashCan} color="#585858" />
              </IconButton>
            </Tooltip>
          )}
          {typeof onEditObservation === 'function' && (
            <Button variant="contained" color="secondary" onClick={() => onEditObservation(observation.survey_id, observation.id)}>
              Edit
            </Button>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
};

const DataTable = ({ survey, observations, onEditObservation, onDeleteObservation, onSort, emptyMessage }) => {
  const [orderBy, setOrderBy] = useState('observation_date');
  const [order, setOrder] = useState('desc');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);

    const sortedObservations = sortObservations(observations, property, newOrder);
    onSort(sortedObservations);
  };

  if (observations.length > 0) {
    return (
      <TableContainer>
        <Table size={'small'} sx={{ marginTop: 4 }}>
          <SortableTableHead survey={survey} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
          <TableBody>
            {observations.map((observation, index) => (
              <DataRow
                key={`${observation.id}_${index}`}
                survey={survey}
                observation={observation}
                onEditObservation={onEditObservation}
                onDeleteObservation={onDeleteObservation}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return <div className="text-center">{emptyMessage}</div>;
  }
};

const ObservationsTable = ({ searchClient, ...props }) => {
  const index = searchClient.initIndex('Care_Observation');
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredObservations, setFilteredObservations] = useState(props.activeObservations || []);

  const filters = props.survey ? `survey.id=${props.survey.id}` : `observer.id=${props.currentUserId} AND org.id=${props.orgId}`;

  const handleSort = (sortedData) => {
    setFilteredObservations(sortedData);
  };

  const handleSearch = useCallback(
    debounce((query) => {
      if (query?.trim() === '') {
        setFilteredObservations(props.activeObservations);
        return;
      }

      index
        .search(query, {
          filters,
          hitsPerPage: 1000,
        })
        .then(({ hits }) => {
          const hitIDs = hits.map((hit) => hit.objectID);
          const newObservations = props.activeObservations.filter((observation) => hitIDs.includes(String(observation.id)));
          setFilteredObservations(newObservations);
        });
    }, 250),
    [props.currentUserId, props.orgId, props.activeObservations]
  );

  const handleInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    handleSearch(query);
  };

  // Initial sort
  useEffect(() => {
    const sortedObservations = sortObservations(props.activeObservations, 'observation_date', 'desc');
    handleSort(sortedObservations);
  }, []);

  return (
    <Paper elevation={2} className="p-3 mt-4">
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <h5 className="blue-header">{props.survey ? 'All Observations' : 'All My Observations'}</h5>
        <TextField
          sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
          size={'small'}
          placeholder="Search"
          variant="outlined"
          value={searchQuery}
          onChange={handleInputChange}
          InputLabelProps={{ shrink: false }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <DataTable
        survey={props.survey || null}
        observations={filteredObservations}
        onEditObservation={props.onEditObservation}
        onDeleteObservation={props.onDeleteObservation}
        onSort={handleSort}
        emptyMessage={props.emptyMessage}
      />
    </Paper>
  );
};

export default ObservationsTable;
