import React, { useEffect, useState } from 'react';
import { TableRow, TableCell, Stack, Button } from '@mui/material';
import { faDownload, faClone, faTrashCan, faChartMixed, faSitemap } from '@fortawesome/pro-regular-svg-icons';
import capitalize from 'lodash/capitalize';
import ActionMenuButton from '../../shared/action_menu_button';
import { useDelegateDialog } from '../../shared/delegations/survey/delegate_dialog_context';
import DelegatedTag from '../../shared/delegations/survey/delegated_tag';
import DelegatedBadge from '../../shared/delegations/survey/delegated_badge';
import { getJobLog } from '../../../apis/job_logs/api';
import { getSurvey } from '../../../apis/care/surveys/api';

const AdminSurveyItem = (props) => {
  const menuItems = [];
  const survey = props.survey;
  const isArchived = survey.archived;
  const org_id = props.org_id;

  const isCreatedByDelegation = survey['created_by_delegation?'];
  const delegatedFromName = survey['delegated_from_org_name'];

  const canDelegate = props.canDelegate || false;
  const canDelegateSurvey = canDelegate && !isArchived && !isCreatedByDelegation;

  const [delegationStatus, setDelegationStatus] = useState({
    isDelegated: survey['delegated?'] || false,
    isInProgress: false,
  });

  const { openDelegateDialog, jobLog, clearJobLog } = useDelegateDialog();

  useEffect(() => {
    if (jobLog?.originElement?.id === survey.id) {
      setDelegationStatus((prev) => ({ ...prev, isInProgress: true }));
      monitorDelegationJob(jobLog.id);
      clearJobLog();
    }
  }, [jobLog]);

  const monitorDelegationJob = (jobId) => {
    const intervalId = setInterval(async () => {
      const log = await getJobLog(jobId);

      if (log.status !== 'pending') {
        clearInterval(intervalId);
        setDelegationStatus((prev) => ({ ...prev, isInProgress: false }));

        if (log.status === 'success') {
          await updateSurveyStatus();
        } else if (log.status === 'failure') {
          alert('Delegation failed. Please try again.');
        }
      }
    }, 2000);
  };

  const updateSurveyStatus = async () => {
    try {
      const surveyResponse = await getSurvey(survey.id, '/care/surveys', { skip_details: true });
      setDelegationStatus((prev) => ({
        ...prev,
        isDelegated: surveyResponse['delegated?'] || false,
      }));
    } catch (error) {
      console.error('Failed to fetch updated survey status:', error);
    }
  };

  if (!isArchived) {
    menuItems.push({
      label: 'Data Analysis',
      icon: faChartMixed,
      onClick: () => (window.location.href = `${props.baseUrlReports}/${survey.id}?${delegationStatus.isDelegated ? 'with_delegations=true' : ''}`),
    });
  }

  menuItems.push({
    label: 'Export data',
    icon: faDownload,
    onClick: () => (window.location.href = `/care/surveys/${survey.id}/export_to_xlsx.xlsx`),
  });

  menuItems.push({
    label: 'Clone',
    icon: faClone,
    onClick: () => props.handleClone(survey.id),
    dataAttributes: {
      'data-cy': 'button-clone-survey',
    },
  });

  if (canDelegateSurvey) {
    menuItems.push({
      label: 'Delegate',
      icon: faSitemap,
      onClick: () => {
        openDelegateDialog(org_id, {
          id: survey.id,
          type: 'survey',
          description: survey.description,
          displayTitle: survey.name,
        });
      },
    });
  }

  if (!isCreatedByDelegation) {
    menuItems.push({
      label: `Delete${delegationStatus.isDelegated ? ' (undelegate before deleting)' : ''}`,
      icon: faTrashCan,
      disabled: delegationStatus.isDelegated,
      onClick: () => props.handleDelete(survey.id),
      dataAttributes: {
        'data-cy': 'button-delete-survey',
      },
    });
  }

  return (
    <TableRow key={survey.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} data-cy={`survey-${survey.id}`}>
      <TableCell data-cy="survey-name">
        <Stack direction="row" spacing={1} alignItems="center">
          <div>{survey.name}</div>
          {delegationStatus.isInProgress && <DelegatedTag isInProgress={true} />}
          {delegationStatus.isDelegated && !delegationStatus.isInProgress && <DelegatedTag />}
          {isCreatedByDelegation && <DelegatedBadge tooltipLabel={`Delegated from ${delegatedFromName}`} />}
        </Stack>
      </TableCell>
      <TableCell>{survey.created_by?.full_name || '-'}</TableCell>
      <TableCell>{new Date(survey.created_at).toLocaleDateString({}, { timeZone: 'UTC' })}</TableCell>
      <TableCell data-cy="survey-status">{capitalize(survey.status)}</TableCell>
      <TableCell align="center">{survey['hierarchical_observations_count']}</TableCell>
      <TableCell sx={{ width: '10%' }}>
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
          <Button
            onClick={() => (window.location.href = survey.edit_path)}
            variant="contained"
            color="secondary"
            data-cy="button-edit-survey"
            disabled={isCreatedByDelegation}
          >
            Edit
          </Button>
          <ActionMenuButton menuItems={menuItems} />
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default AdminSurveyItem;
