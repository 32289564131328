import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AspectRow from './aspect_row';
import { isDetail, groupObservationsBy, areAllNodesSelected, hasAtLeastOneCheckedNode, initializeCheckedState } from './utils';

import AdmConfigDialog from './adm_config_dialog';
import { tableContainerStyles, tableStyles, headerContainerStyles, headerTitleStyles, headerCellStyles } from './styles';

import {
  Typography,
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Switch,
  FormGroup,
  FormControl,
  FormControlLabel,
  Select,
  InputLabel,
  MenuItem,
  Stack,
  Button,
  Badge,
  Link,
} from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSliders } from '@fortawesome/pro-regular-svg-icons';

const TableHeader = ({ showHqimColumns, showContentColumns, showSummaryOnly, groupName, groupIndex }) => {
  const headerCellWidth = showSummaryOnly || !(showHqimColumns || showContentColumns) ? '40%' : '20%';
  return (
    <>
      <Box sx={headerContainerStyles}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ ...headerTitleStyles }} colSpan="1">
                <Box sx={{ position: 'relative', left: '-24px' }}>
                  <Typography variant="h6" color="primary">
                    {groupName !== 'default' && <strong>{groupName}</strong>}
                  </Typography>
                </Box>
              </TableCell>
              {groupIndex === 0 ? (
                <>
                  <TableCell sx={{ ...headerCellStyles(headerCellWidth) }} colSpan="1">
                    <Typography variant="body1" color="white">
                      ALL
                    </Typography>
                  </TableCell>
                  {!showSummaryOnly && showHqimColumns && (
                    <>
                      <TableCell sx={{ ...headerCellStyles(headerCellWidth) }} colSpan="1">
                        <Typography variant="body1" color="white">
                          HQIM
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ ...headerCellStyles(headerCellWidth) }} colSpan="1">
                        <Typography variant="body1" color="white">
                          NO HQIM
                        </Typography>
                      </TableCell>
                    </>
                  )}
                  {!showSummaryOnly && showContentColumns && (
                    <>
                      <TableCell sx={{ ...headerCellStyles(headerCellWidth) }} colSpan="1">
                        <Typography variant="body1" color="white">
                          ELA
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ ...headerCellStyles(headerCellWidth) }} colSpan="1">
                        <Typography variant="body1" color="white">
                          MATH
                        </Typography>
                      </TableCell>
                    </>
                  )}
                </>
              ) : (
                <>
                  <TableCell sx={{ borderBottom: '0', padding: '8px 4px' }} colSpan="3">
                    &nbsp;
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
        </Table>
      </Box>
    </>
  );
};

const AspectTable = ({ aspect, index, isFirstVisibleAspect, showSummaryOnly, ...props }) => {
  return (
    <Box sx={{ ...tableContainerStyles, marginTop: isFirstVisibleAspect ? '0' : '1.5rem' }}>
      <Table sx={tableStyles}>
        <TableBody>
          <AspectRow
            aspect={aspect}
            observations={props.observations}
            showHqimColumns={props.showHqimColumns}
            showContentColumns={props.showContentColumns}
            showFractions={props.showFractions}
            showAdmNumbers={props.showAdmNumbers}
            showSummaryOnly={showSummaryOnly}
            surveyTypeCategoryName={props.surveyTypeCategoryName}
            setDrawerAdmNode={props.setDrawerAdmNode}
          />
        </TableBody>
      </Table>
    </Box>
  );
};

const AspectCategory = ({
  aspectCategory,
  observations,
  showHqimColumns,
  showContentColumns,
  toggleColumns,
  showFractions,
  toggleFractions,
  isFirstAspectCategory,
  setDrawerAdmNode,
  surveyTypeCategoryName,
  groupBy,
  setGroupBy,
  setIsAdmConfigDialogOpen,
  allNodesSelected,
  showHqimControls,
  showPredominantlyPocControls,
  showAdmNumbers,
  showSummaryOnly,
}) => {
  const isDetailSurvey = isDetail(surveyTypeCategoryName);
  const groupedObservations = groupObservationsBy(groupBy, observations);
  const visibleAspects = aspectCategory.aspects.filter((aspect) => hasAtLeastOneCheckedNode(aspect));

  return (
    <>
      {isFirstAspectCategory && (
        <TableControls
          showFractions={showFractions}
          showHqimColumns={showHqimColumns}
          showContentColumns={showContentColumns}
          toggleFractions={toggleFractions}
          toggleColumns={toggleColumns}
          groupBy={groupBy}
          setGroupBy={setGroupBy}
          setIsAdmConfigDialogOpen={setIsAdmConfigDialogOpen}
          allNodesSelected={allNodesSelected}
          showHqimControls={showHqimControls}
          showPredominantlyPocControls={showPredominantlyPocControls}
          showSummaryOnly={showSummaryOnly}
          observations={observations}
        />
      )}
      <Stack direction="row" className={isFirstAspectCategory ? 'mt-3' : 'mt-5'} alignItems="center" spacing={2}>
        {aspectCategory.checked && (
          <>
            <Typography variant="h3" color="primary">
              {aspectCategory.name}
            </Typography>
            {aspectCategory.survey_config.allow_notes && (
              <Link onClick={() => setDrawerAdmNode(aspectCategory)} style={{ display: 'inline-flex', alignItems: 'center' }}>
                Comments
              </Link>
            )}
          </>
        )}
      </Stack>
      <Box>
        {Object.entries(groupedObservations).map(([groupName, observationsInGroup], groupIndex) => (
          <Box key={groupName} sx={{ marginBottom: '1rem' }}>
            {visibleAspects.length > 0 && (
              <>
                <TableHeader
                  showHqimColumns={showHqimColumns}
                  showContentColumns={showContentColumns}
                  showSummaryOnly={showSummaryOnly}
                  groupName={groupName}
                  groupIndex={groupIndex}
                />
                {isDetailSurvey ? (
                  visibleAspects.map((aspect, index) => (
                    <AspectTable
                      key={aspect.id}
                      aspect={aspect}
                      index={index}
                      observations={observationsInGroup}
                      showHqimColumns={showHqimColumns}
                      showContentColumns={showContentColumns}
                      showFractions={showFractions}
                      showAdmNumbers={showAdmNumbers}
                      showSummaryOnly={showSummaryOnly}
                      surveyTypeCategoryName={surveyTypeCategoryName}
                      setDrawerAdmNode={setDrawerAdmNode}
                      isFirstVisibleAspect={index === 0}
                    />
                  ))
                ) : (
                  <Box sx={tableContainerStyles}>
                    <Table sx={tableStyles}>
                      <TableBody>
                        {visibleAspects.map((aspect) => (
                          <AspectRow
                            key={aspect.id}
                            aspect={aspect}
                            observations={observationsInGroup}
                            showHqimColumns={showHqimColumns}
                            showContentColumns={showContentColumns}
                            showFractions={showFractions}
                            showAdmNumbers={showAdmNumbers}
                            showSummaryOnly={showSummaryOnly}
                            surveyTypeCategoryName={surveyTypeCategoryName}
                            setDrawerAdmNode={setDrawerAdmNode}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                )}
              </>
            )}
          </Box>
        ))}
      </Box>
    </>
  );
};

const renderObservableTypes = (observations) => {
  const observableTypes = [...new Set(observations.map((o) => o.observable?.observable_type?.name))];
  return observableTypes.length === 1 ? observableTypes[0] : 'Observee';
};

const TableControls = ({
  showFractions,
  showHqimColumns,
  showContentColumns,
  toggleFractions,
  toggleColumns,
  groupBy,
  setGroupBy,
  setIsAdmConfigDialogOpen,
  allNodesSelected,
  showHqimControls,
  showPredominantlyPocControls,
  showSummaryOnly,
  observations,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        position: 'sticky',
        top: '60px',
        zIndex: 1000,
        height: '70px',
        backgroundColor: '#fff',
        borderBottom: '1px solid #ccc',
      }}
    >
      <FormGroup
        className="no-print"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          paddingRight: '24px',
        }}
      >
        <FormControlLabel
          value="start"
          control={<Switch checked={showFractions} onChange={toggleFractions} color="primary" />}
          label="Raw Data"
          labelPlacement="start"
        />

        {!showSummaryOnly &&
          (showHqimControls ? (
            <FormControlLabel
              value="start"
              control={<Switch checked={showHqimColumns} onChange={toggleColumns} color="primary" />}
              label="HQIM Columns"
              labelPlacement="start"
            />
          ) : (
            <FormControlLabel
              value="start"
              control={<Switch checked={showContentColumns} onChange={toggleColumns} color="primary" />}
              label="Content Columns"
              labelPlacement="start"
            />
          ))}

        <FormControl sx={{ width: 180, marginLeft: 2 }} size="small">
          <InputLabel id="group-by-select-label">Group By</InputLabel>
          <Select labelId="group-by-select-label" id="group-by-select" label="Group By" value={groupBy} onChange={(e) => setGroupBy(e.target.value)}>
            <MenuItem value="default">None</MenuItem>
            <MenuItem value="school_name">School Name</MenuItem>
            <MenuItem value="grade_level">Grade (individual)</MenuItem>
            <MenuItem value="grade_range">Grade (range)</MenuItem>
            <MenuItem value="content_area">Content</MenuItem>
            <MenuItem value="observable">{renderObservableTypes(observations)}</MenuItem>
            <MenuItem value="instruction_language">Instruction Language</MenuItem>
            {showPredominantlyPocControls && <MenuItem value="diverse_classroom">Diverse Classroom</MenuItem>}
            <MenuItem value="observer">Observer</MenuItem>
          </Select>
        </FormControl>

        {!allNodesSelected && (
          <Badge badgeContent={1} color="primary">
            <Button
              id="adm-config-dialog"
              variant="outlined"
              onClick={() => setIsAdmConfigDialogOpen(true)}
              sx={{ marginLeft: 2 }}
              endIcon={<FontAwesomeIcon icon={faSliders} />}
            >
              Customize
            </Button>
          </Badge>
        )}
        {allNodesSelected && (
          <Button
            id="adm-config-dialog"
            variant="outlined"
            onClick={() => setIsAdmConfigDialogOpen(true)}
            sx={{ marginLeft: 2 }}
            endIcon={<FontAwesomeIcon icon={faSliders} />}
          >
            Customize
          </Button>
        )}
      </FormGroup>
    </Box>
  );
};

const HeatMaps = ({
  surveyTypeCategoryName,
  admTree,
  observations,
  setDrawerAdmNode,
  showHqimControls,
  showPredominantlyPocControls,
  showSummaryOnly,
  showAdmNumbers,
  initialGroupBy = 'default',
}) => {
  const [showHqimColumns, setShowHqimColumns] = useState(showHqimControls);
  const [showContentColumns, setShowContentColumns] = useState(!showHqimControls);
  const [showFractions, setShowFractions] = useState(true);
  const [groupBy, setGroupBy] = useState(initialGroupBy);
  const [isAdmConfigDialogOpen, setIsAdmConfigDialogOpen] = useState(false);
  const [customAdmTree, setCustomAdmTree] = useState(() => initializeCheckedState(admTree));

  useEffect(() => {
    setGroupBy(initialGroupBy);
  }, [initialGroupBy]);

  const toggleColumns = () => {
    if (showHqimControls) {
      setShowHqimColumns(!showHqimColumns);
    } else {
      setShowContentColumns(!showContentColumns);
    }
  };

  const toggleFractions = () => setShowFractions(!showFractions);
  const handleAdmTreeChange = (newTree) => {
    setCustomAdmTree(newTree);
  };

  const allNodesSelected = areAllNodesSelected(customAdmTree);

  return (
    <div className="mt-0 care-heatmap ensure-page-break-before">
      {customAdmTree
        .filter(
          (aspectCategory) =>
            aspectCategory?.aspects?.length > 0 || (aspectCategory.survey_config.allow_notes && aspectCategory.survey_config.required)
        )
        .map((aspectCategory, index) => (
          <AspectCategory
            isFirstAspectCategory={index === 0}
            key={aspectCategory.id}
            aspectCategory={aspectCategory}
            observations={observations}
            showHqimColumns={showHqimColumns}
            showContentColumns={showContentColumns}
            toggleColumns={toggleColumns}
            showFractions={showFractions}
            toggleFractions={toggleFractions}
            surveyTypeCategoryName={surveyTypeCategoryName}
            setDrawerAdmNode={setDrawerAdmNode}
            groupBy={groupBy}
            setGroupBy={setGroupBy}
            setIsAdmConfigDialogOpen={setIsAdmConfigDialogOpen}
            allNodesSelected={allNodesSelected}
            showHqimControls={showHqimControls}
            showPredominantlyPocControls={showPredominantlyPocControls}
            showAdmNumbers={showAdmNumbers}
            showSummaryOnly={showSummaryOnly}
          />
        ))}
      <AdmConfigDialog
        admTree={customAdmTree}
        isDialogOpen={isAdmConfigDialogOpen}
        setIsDialogOpen={setIsAdmConfigDialogOpen}
        handleAdmTreeChange={handleAdmTreeChange}
        isDetailSurvey={isDetail(surveyTypeCategoryName)}
      />
    </div>
  );
};

HeatMaps.propTypes = {
  observations: PropTypes.array.isRequired,
  admTree: PropTypes.array.isRequired,
  surveyTypeCategoryName: PropTypes.string,
  setDrawerAdmNode: PropTypes.func,
};

export default HeatMaps;
