import { getHeaders } from '../../headers';

export const createDataModel = async (data_model, baseUrl) => {
  const response = await fetch(`${baseUrl}`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ data_model }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw errorData.errors ? errorData.errors : new Error(response.statusText);
  }
  return response.json();
};

export const updateDataModel = async (data_model, baseUrl) => {
  const response = await fetch(`${baseUrl}/${data_model.id}`, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify({ data_model }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw errorData.errors ? errorData.errors : new Error(response.statusText);
  }
  return response.json();
};

export const deleteDataModel = async (id, baseUrl) => {
  const response = await fetch(`${baseUrl}/${id}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw errorData.errors ? errorData.errors : new Error(response.statusText);
  }
  return response.json();
};

export const createDataModelNode = async (node, baseUrl) => {
  const response = await fetch(`${baseUrl}`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ node }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw errorData.errors ? errorData.errors : new Error(response.statusText);
  }
  return response.json();
};

export const updateDataModelNode = async (node, baseUrl) => {
  const response = await fetch(`${baseUrl}/${node.id}`, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify({ node }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw errorData.errors ? errorData.errors : new Error(response.statusText);
  }
  return response.json();
};

export const deleteDataModelNode = async (node, baseUrl) => {
  const response = await fetch(`${baseUrl}/${node.id}`, {
    method: 'DELETE',
    headers: getHeaders(),
    body: JSON.stringify({ node }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw errorData.errors ? errorData.errors : new Error(response.statusText);
  }
  return response.json();
};
