const hasNonNullResponse = (response) => {
  return response.value !== null && response.value !== undefined;
};

const isMatchingAdmNode = (response, admNode) => {
  return response.adm_node_id === admNode.id && response.adm_node_type === admNode.type;
};

const observationsWithResponseForAdmNode = (observations, admNode) => {
  return observations
    .filter((o) => o.status === 'completed')
    .filter((o) => o.responses.some((response) => isMatchingAdmNode(response, admNode) && response.value !== 'na'));
};

const sumResponsesByAdmNode = (observations, admNode) => {
  return observationsWithResponseForAdmNode(observations, admNode)
    .map((o) => {
      return o.responses.find((response) => isMatchingAdmNode(response, admNode));
    })
    .filter((response) => hasNonNullResponse(response))
    .map((response) => parseInt(response.value))
    .reduce((sum, x) => sum + x, 0);
};

const observationsWithResponseForAdmNodeByValue = (observations, admNode, value) => {
  return observations
    .filter((o) => o.status === 'completed')
    .filter((o) => o.responses.some((response) => hasNonNullResponse(response) && isMatchingAdmNode(response, admNode) && response.value === value));
};

export { observationsWithResponseForAdmNode, observationsWithResponseForAdmNodeByValue, sumResponsesByAdmNode };
