import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../../layouts/application';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../../constants/theme';
import { ConfirmDialogProvider } from '../../../components/modals/confirm_dialog';
import CareNav from '../../../components/care/shared/nav';
import ObservationTable from '../../../components/care/coaching_dashboards/observation_table';
import { Typography, Grid, Stack } from '@mui/material';
import { OBSERVATION_TABLE_MODES } from '../../../components/care/coaching_dashboards/utils';
import GoalsTable from '../../../components/care/goals/goals_table';
import useGoalColumns from '../../../components/care/goals/use_goal_columns';
import ObserverSummary from '../../../components/care/coaching_dashboards/observer_summary';

const Container = ({ observations, coach, goals, summary_data, leads, recipients, goal_types, goal_indicators, ...props }) => {
  const goals_url = props.page_props.goals.url;
  const columnConfig = useGoalColumns({
    showDescriptors: props.goal_descriptors_enabled,
    goalIndicators: goal_indicators,
    goalTypes: goal_types,
    configType: 'singleObserver',
  });

  const coachName = () => `${coach.first_name} ${coach.last_name}`;

  return (
    <ConfirmDialogProvider>
      <CareNav {...props} />
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Typography variant="h4">Observer: {coachName()}</Typography>
      </Stack>

      <ObserverSummary observations={observations} summaryData={summary_data} />

      <GoalsTable
        title="All Goals"
        goals={goals}
        enableActionSteps={false}
        canAddActionStep={false}
        enableTableActions={true}
        columnConfig={columnConfig}
        tableName="observerDashGoals"
        goalsBaseUrl={goals_url}
      />

      <ObservationTable
        title="All Observations"
        observations={observations}
        baseUrl={props.base_url}
        mode={OBSERVATION_TABLE_MODES.COACH}
        leads={leads}
        recipients={recipients}
        observer={coach}
        canShowCompare={false}
      />

      <Grid container spacing={2}>
        <Grid item md={6}></Grid>
        <Grid item md={6}></Grid>
      </Grid>
    </ConfirmDialogProvider>
  );
};

Container.propTypes = {
  back_url: PropTypes.string.isRequired,
  base_url: PropTypes.string.isRequired,
  summary_data: PropTypes.object,
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <Container {...props}></Container>
      </ThemeProvider>
    </Layout>
  );
};
