import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import ReadOnlyRichText from '../../../../components/shared/rte/readonly_rich_text';

const Notes = ({ admNode, observations }) => {
  const notes = observations
    .map((observation) => {
      return {
        observer: observation.observer.full_name,
        response: observation.responses.find((r) => r.adm_node_id === admNode.id && r.adm_node_type === admNode.type).notes,
        school_name: observation.school_name.name,
        grade_level: observation.grade_level,
        observable: observation?.observable?.name,
      };
    })
    .filter((observation) => observation.response !== null);

  const renderNoteMetadata = (note) => {
    return (
      <Typography>
        <strong>{note.observer}</strong> • {note.school_name} • {note.grade_level} {note.observable && `• ${note.observable}`}
      </Typography>
    );
  };
  return (
    <>
      {notes.length > 0 && <Typography variant="h6"> Comments </Typography>}
      {notes.length > 0 &&
        notes.map((note, index) => {
          return (
            <div style={{ marginTop: 20, marginLeft: 10 }} key={index}>
              {renderNoteMetadata(note)}
              {/*<Typography variant="body1">{note.response}</Typography>*/}
              <ReadOnlyRichText initialContent={note.response} customStyle={{ marginTop: '2px' }} />
            </div>
          );
        })}
    </>
  );
};

Notes.propTypes = {
  observations: PropTypes.array.isRequired,
  admNode: PropTypes.object.isRequired,
};

export default Notes;
