import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Layout from '../../layouts/application';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../../constants/theme';
import CareNav from '../../../components/care/shared/nav';
import AddGoals from '../../../components/care/goals/add_goals';
import GoalsTable from '../../../components/care/goals/goals_table';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { createGoal } from '../../../apis/care/goals/api';
import { Snackbar } from '@mui/material';
import { ConfirmDialogProvider } from '../../../components/modals/confirm_dialog';
import useGoalColumns from '../../../components/care/goals/use_goal_columns';

const Container = ({ observables, goals, goal_indicators, goal_types, leads, ...props }) => {
  const [allGoals, setAllGoals] = useState(goals);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [errors, setErrors] = useState({});

  const goalBaseUrl = props.base_url;
  const actionStepsUrl = props.page_props.action_steps.url;

  const handleSave = async (payload) => {
    setIsSubmitting(true);
    try {
      const response = await createGoal(payload, goalBaseUrl);
      setAllGoals([...allGoals, ...response]);
      setToastOpen(true);

      const goalCount = response.length;
      const message = `${goalCount} goal${goalCount === 1 ? '' : 's'} successfully added.`;
      setToastMessage(message);
      setIsSubmitting(false);
      setResetForm(true);
      setErrors({});
    } catch (error) {
      setToastOpen(false);
      setToastMessage('');
      setIsSubmitting(false);
      setResetForm(false);
      setErrors(error);
    }
  };

  const columnConfig = useGoalColumns({
    leads,
    goalIndicators: goal_indicators,
    goalTypes: goal_types,
    showDescriptors: props.goal_descriptors_enabled,
    configType: 'allGoals',
  });

  const initialState = {
    sorting: {
      sortModel: [{ field: 'observable', sort: 'asc' }],
    },
  };

  return (
    <ConfirmDialogProvider>
      <CareNav {...props} back_url={''} />
      <AddGoals
        baseUrl={goalBaseUrl}
        observables={observables}
        goalTypes={goal_types}
        goalIndicators={goal_indicators}
        leads={leads}
        onSave={handleSave}
        isSubmitting={isSubmitting}
        resetForm={resetForm}
        goalDescriptorsEnabled={props.goal_descriptors_enabled}
        errors={errors}
      />
      <GoalsTable
        title="Teacher Goals"
        goals={allGoals}
        leads={leads}
        enableTableActions={true}
        actionStepsUrl={actionStepsUrl}
        columnConfig={columnConfig}
        tableName="allGoals"
        goalsBaseUrl={goalBaseUrl}
        initialState={initialState}
      />
      <Snackbar open={toastOpen} autoHideDuration={5000} onClose={() => setToastOpen(false)} message={toastMessage} />
    </ConfirmDialogProvider>
  );
};

Container.propTypes = {
  base_url: PropTypes.string.isRequired,
  back_url: PropTypes.string,
  goal_descriptors_enabled: PropTypes.bool,
  goals: PropTypes.array,
  goal_types: PropTypes.array,
  goal_indicators: PropTypes.array,
  observables: PropTypes.array,
  leads: PropTypes.array,
  permission_props: PropTypes.object.isRequired,
  page_props: PropTypes.object.isRequired,
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Container {...props}></Container>
        </LocalizationProvider>
      </ThemeProvider>
    </Layout>
  );
};
