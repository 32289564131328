import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Layout from '../../layouts/application';
import ShowFilters from '../../../components/care/reports/filters/show_filters';
import ObservationsContent from '../../../components/care/reports/observations/content';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../../constants/theme';
import { Grid, IconButton, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/pro-regular-svg-icons';
import { isGradeInSelectedRanges } from '../../../components/care/reports/filters/utils';
import CareNav from '../../../components/care/shared/nav';
import { hideClassGroupingData } from '../../../components/care/shared/utils';

const Container = (props) => {
  const { observations, leads, recipients, surveys, observable_name, observer_name } = props;
  const [filteredObservations, setFilteredObservations] = useState(observations);
  const [selectedSchools, setSelectedSchools] = useState(new Set());
  const [selectedObservables, setSelectedObservables] = useState(new Set());
  const [selectedObservers, setSelectedObservers] = useState(new Set());
  const [selectedGradeLevels, setSelectedGradeLevels] = useState(new Set());
  const [selectedGradeRanges, setSelectedGradeRanges] = useState(new Set());
  const [selectedContentAreas, setSelectedContentAreas] = useState(new Set());
  const [selectedInstructionLanguages, setSelectedInstructionLanguages] = useState(new Set());
  const [selectedDiversityOptions, setSelectedDiversityOptions] = useState(new Set());
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [initialGroupBy, setInitialGroupBy] = useState('default');

  const hideClassRoomEngagement = useMemo(() => {
    return surveys.every((survey) => hideClassGroupingData(survey));
  }, [surveys]);

  /**
   * Filter observations based on selected schools and observables
   */
  useEffect(() => {
    if (
      selectedSchools.size > 0 ||
      selectedObservables.size > 0 ||
      selectedObservers.size > 0 ||
      selectedGradeLevels.size > 0 ||
      selectedGradeRanges.size > 0 ||
      selectedContentAreas.size > 0 ||
      selectedInstructionLanguages.size > 0 ||
      selectedDiversityOptions.size > 0
    ) {
      const newFilteredObservations = observations.filter((o) => {
        return (
          (selectedSchools.size === 0 || selectedSchools.has(o.school_name?.id)) &&
          (selectedObservables.size === 0 || selectedObservables.has(o.observable?.id)) &&
          (selectedObservers.size === 0 || selectedObservers.has(o.observer?.id)) &&
          (selectedGradeLevels.size === 0 || selectedGradeLevels.has(o.grade_level)) &&
          (selectedGradeRanges.size === 0 || isGradeInSelectedRanges(o.grade_level, selectedGradeRanges)) &&
          (selectedContentAreas.size === 0 || selectedContentAreas.has(o.content_area?.id)) &&
          (selectedInstructionLanguages.size === 0 || selectedInstructionLanguages.has(o.instruction_language?.id)) &&
          (selectedDiversityOptions.size === 0 || selectedDiversityOptions.has(o.diverse_classroom))
        );
      });
      setFilteredObservations(newFilteredObservations);
      setIsFilterActive(true);
    } else {
      setFilteredObservations(observations);
      setIsFilterActive(false);
    }
  }, [
    selectedSchools,
    selectedObservables,
    selectedObservers,
    selectedGradeLevels,
    selectedGradeRanges,
    selectedContentAreas,
    selectedInstructionLanguages,
    selectedDiversityOptions,
  ]);

  const handleFilterChange = (setSelected, idOrIds) => {
    // Ensure always an array
    const ids = Array.isArray(idOrIds) ? idOrIds : [idOrIds];

    ids.forEach((id) => {
      setSelected((prevSelected) => {
        const newSelected = new Set(prevSelected);
        newSelected.has(id) ? newSelected.delete(id) : newSelected.add(id);
        return newSelected;
      });
    });
  };

  const clearAllFilters = () => {
    setSelectedSchools(new Set());
    setSelectedObservables(new Set());
    setSelectedObservers(new Set());
    setSelectedGradeLevels(new Set());
    setSelectedGradeRanges(new Set());
    setSelectedContentAreas(new Set());
    setSelectedInstructionLanguages(new Set());
    setSelectedDiversityOptions(new Set());
  };

  const clearFilters = (filterName) => {
    if (!filterName) {
      clearAllFilters();
      return;
    }
    switch (filterName) {
      case 'school_name':
        setSelectedSchools(new Set());
        break;
      case 'observable':
        setSelectedObservables(new Set());
        break;
      case 'observer':
        setSelectedObservers(new Set());
        break;
      case 'grade_level':
        setSelectedGradeLevels(new Set());
        break;
      case 'grade_range':
        setSelectedGradeRanges(new Set());
        break;
      case 'content_area':
        setSelectedContentAreas(new Set());
        break;
      case 'instruction_language':
        setSelectedInstructionLanguages(new Set());
        break;
      case 'diverse_classroom':
        setSelectedDiversityOptions(new Set());
        break;
    }
  };

  const surveyNames = () => [...new Set(surveys.map((survey) => survey.name))];

  const contextTitle = () => {
    if (observable_name) {
      return `Observations for ${observable_name}`;
    } else if (observer_name) {
      return `Observations by ${observer_name}`;
    }
    return surveyNames().join(', ');
  };

  return (
    <>
      <CareNav {...props} back_url={''} />
      <div className="mt-2 care-reporting">
        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
          <h3>{contextTitle()}</h3>
          <IconButton aria-label="Print" size="small" onClick={() => window.print()} className="no-print">
            <FontAwesomeIcon icon={faPrint} color="#585858" />
          </IconButton>
        </Stack>
        <Grid container columnSpacing={{ xs: 4 }} wrap="wrap" marginTop={5}>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            xl={2}
            className="no-print"
            sx={{
              position: { xs: 'relative', md: 'sticky' },
              top: { md: '100px' },
              height: '100%',
            }}
          >
            <ShowFilters
              onSchoolFilterChange={(idOrIds) => handleFilterChange(setSelectedSchools, idOrIds)}
              onObservableFilterChange={(idOrIds) => handleFilterChange(setSelectedObservables, idOrIds)}
              onObserverFilterChange={(idOrIds) => handleFilterChange(setSelectedObservers, idOrIds)}
              onGradeLevelFilterChange={(idOrIds) => handleFilterChange(setSelectedGradeLevels, idOrIds)}
              onGradeRangeFilterChange={(idOrIds) => handleFilterChange(setSelectedGradeRanges, idOrIds)}
              onContentAreaFilterChange={(idOrIds) => handleFilterChange(setSelectedContentAreas, idOrIds)}
              onInstructionLanguageFilterChange={(idOrIds) => handleFilterChange(setSelectedInstructionLanguages, idOrIds)}
              onDiversityOptionFilterChange={(idOrIds) => handleFilterChange(setSelectedDiversityOptions, idOrIds)}
              selectedSchools={selectedSchools}
              selectedObservables={selectedObservables}
              selectedObservers={selectedObservers}
              selectedGradeLevels={selectedGradeLevels}
              selectedGradeRanges={selectedGradeRanges}
              selectedContentAreas={selectedContentAreas}
              selectedInstructionLanguages={selectedInstructionLanguages}
              selectedDiversityOptions={selectedDiversityOptions}
              surveys={surveys}
              observations={observations}
              isFilterActive={isFilterActive}
              onClearFilters={clearFilters}
              hideClassRoomEngagement={hideClassRoomEngagement}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
            <ObservationsContent
              observations={filteredObservations}
              leads={leads}
              recipients={recipients}
              surveys={surveys}
              hideClassRoomEngagement={hideClassRoomEngagement}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

Container.propTypes = {
  base_url: PropTypes.string.isRequired,
  observable_name: PropTypes.string,
  observer_name: PropTypes.string,
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <Container {...props}></Container>
      </ThemeProvider>
    </Layout>
  );
};
