import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Layout from '../../layouts/application';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../../constants/theme';
import ActiveSurveys from '../../../components/care/surveys/active_surveys';
import CareNav from '../../../components/care/shared/nav';
import ObservationsTable from '../../../components/care/observations/table';
import ObservationDialogAdapter from '../../../components/care/observations/observation_dialog_adapter';
import { getSurvey } from '../../../apis/care/surveys/api';
import { createObservation, deleteObservation, getObservation } from '../../../apis/care/observations/api';
import { ConfirmDialogProvider, useConfirmDialog } from '../../../components/modals/confirm_dialog';
import algoliasearch from 'algoliasearch/lite';

const Container = (props) => {
  const [survey, setSurvey] = React.useState(null);
  const [observation, setObservation] = React.useState(null);
  const [isObservationDialogOpen, setIsObservationDialogOpen] = useState(false);
  const [activeSurveys, setActiveSurveys] = useState(props.active_surveys || []);
  const [activeObservations, setActiveObservations] = useState(props.observations || []);
  const { showConfirmDialog, hideConfirmDialog } = useConfirmDialog();

  const searchClient = useMemo(() => algoliasearch(props.algoliaAppId, props.algoliaSearchKey), [props.algoliaAppId, props.algoliaSearchKey]);

  const refreshSurvey = async () => {
    if (survey?.id) {
      const surveyResponse = await getSurvey(survey.id);

      // Update the activeSurveys to reflect the Observation count.
      const updatedSurveys = activeSurveys.map((survey) => {
        if (survey.id === surveyResponse.id) {
          return surveyResponse;
        }
        return survey;
      });
      setActiveSurveys(updatedSurveys);
    }
  };

  const handleEditObservation = async (surveyId, observationId) => {
    try {
      const [surveyResponse, observationResponse] = await Promise.all([getSurvey(surveyId), getObservation(observationId)]);

      setSurvey(surveyResponse);
      setObservation(observationResponse);
      setIsObservationDialogOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseObservation = async (newObservation) => {
    setIsObservationDialogOpen(false);

    if (newObservation?.id) {
      await refreshSurvey();
      setActiveObservations((prevObservations) => {
        const observationIndex = activeObservations.findIndex((observation) => observation.id === newObservation.id);
        if (observationIndex > -1) {
          const updatedObservations = [...prevObservations];
          updatedObservations[observationIndex] = newObservation;
          return updatedObservations;
        } else {
          return [...prevObservations, newObservation];
        }
      });
    }
  };

  const handleDeleteObservation = async (surveyId, observationId) => {
    try {
      await deleteObservation(observationId);

      const observationIdAsNumber = Number(observationId);
      const updatedObservations = [...activeObservations.filter((obs) => obs.id !== observationIdAsNumber)];
      setActiveObservations(updatedObservations);

      // Reflect the observations_count in the affected survey.
      const updatedSurveys = activeSurveys.map((survey) => {
        if (survey.id === surveyId) {
          return {
            ...survey,
            observations_count: survey.observations_count - 1,
          };
        }
        return survey;
      });
      setActiveSurveys(updatedSurveys);

      hideConfirmDialog();
    } catch (error) {
      console.error(error);
    }
  };

  const confirmDeleteObservation = (surveyId, observationId) => {
    showConfirmDialog({
      title: 'Delete Confirmation',
      description: 'Are you sure you want to delete this Observation?',
      actions: [
        {
          label: 'Cancel',
          onClick: () => hideConfirmDialog(),
          variant: 'text',
          color: 'primary',
        },
        {
          label: 'Delete',
          onClick: () => handleDeleteObservation(surveyId, observationId),
          variant: 'contained',
          color: 'primary',
          autoFocus: false,
        },
      ],
      maxWidth: 'xs',
    });
  };

  const [loadingObservationStates, setLoadingObservationStates] = useState({});

  const handleCreateObservation = async (surveyId) => {
    if (loadingObservationStates[surveyId]) return;

    try {
      setLoadingObservationStates((prev) => ({ ...prev, [surveyId]: true }));

      let observationsBaseUrl = `${props.base_url}/${surveyId}/observations`;
      const surveyPromise = getSurvey(surveyId);
      const observationPromise = createObservation({ observation: {} }, observationsBaseUrl);

      const [survey, newObservation] = await Promise.all([surveyPromise, observationPromise]);

      setSurvey(survey);
      setObservation(newObservation);
      setIsObservationDialogOpen(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingObservationStates((prev) => ({ ...prev, [surveyId]: false }));
    }
  };

  const { can_view_all_observation_data } = props.permission_props || false;
  const { can_add_internal_teachers } = props.permission_props || false;

  const goals_url = props.page_props.goals.url;
  const action_steps_url = props.page_props.action_steps.url;

  return (
    <>
      <CareNav {...props} back_url={''} />
      <ActiveSurveys
        surveys={activeSurveys}
        onCreateObservation={handleCreateObservation}
        loadingObservationStates={loadingObservationStates}
        baseUrlReports={props.base_url_reports}
        canViewActionMenu={can_view_all_observation_data}
      />
      <ObservationsTable
        searchClient={searchClient}
        onDeleteObservation={confirmDeleteObservation}
        onEditObservation={handleEditObservation}
        activeObservations={activeObservations}
        key={JSON.stringify(activeObservations)}
        emptyMessage="You have created no observations."
        {...props}
      />
      {isObservationDialogOpen && (
        <ObservationDialogAdapter
          survey={survey}
          observation={observation}
          baseUrl={props.base_url}
          observableGoalsBaseUrl={goals_url}
          actionStepsBaseUrl={action_steps_url}
          onClose={handleCloseObservation}
          leads={props.leads}
          recipients={props.recipients}
          can_add_internal_teachers={can_add_internal_teachers}
        />
      )}
    </>
  );
};

Container.propTypes = {
  algoliaAppId: PropTypes.string.isRequired,
  algoliaSearchKey: PropTypes.string.isRequired,
  currentUserId: PropTypes.number.isRequired,
  orgId: PropTypes.number.isRequired,
  base_url: PropTypes.string.isRequired,
  base_url_reports: PropTypes.string.isRequired,
  active_surveys: PropTypes.array,
  observations: PropTypes.array,
  leads: PropTypes.array,
  recipients: PropTypes.array,
  only_care_tool_observed_user: PropTypes.bool,
  permission_props: PropTypes.object.isRequired,
  page_props: PropTypes.object.isRequired,
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <ConfirmDialogProvider>
          <Container {...props}></Container>
        </ConfirmDialogProvider>
      </ThemeProvider>
    </Layout>
  );
};
