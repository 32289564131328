import React from 'react';
import PropTypes from 'prop-types';
import { getColor, getPointScaleColor } from './heatmap_colors';
import { computeHeatMapValuesForAdmNode, formatPercentage, isDetail, isAspect, isSummaryIndicator, isTitleNode } from './utils';
import { nodeCellStyles, nodeTitleStyles, nodeTitleNumberStyles } from './styles';
import { TableCell, TableRow, Tooltip, Typography, Zoom } from '@mui/material';
import { getPointBucketFromValue } from './utils';
import { isPointScaleQuestion, getNumPoints } from '../../shared/utils';

const AdmNodeRowTitleTooltip = ({ title, children, ...props }) => {
  return (
    <Tooltip
      TransitionComponent={Zoom}
      placement="bottom"
      enterDelay={1300}
      enterNextDelay={1300}
      arrow={false}
      title={title}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -20],
            },
          },
        ],
      }}
      {...props}
    >
      <div>{children}</div>
    </Tooltip>
  );
};

const AdmNodeRowTitle = ({ admNode, surveyTypeCategoryName, showAdmNumbers }) => {
  const isAspectNode = isAspect(admNode.type);
  const isSummaryIndicatorNode = isSummaryIndicator(admNode.type);
  const isDetailSurvey = isDetail(surveyTypeCategoryName);

  const typographyStyles = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  };

  let typographyVariant = 'body1';
  let color = 'inherit';

  if (isDetailSurvey) {
    if (isAspectNode) {
      typographyVariant = 'h5';
      color = 'primary';
    } else if (isSummaryIndicatorNode) {
      typographyVariant = 'h6';
      color = 'primary';
    }
  } else {
    if (isAspectNode) {
      typographyVariant = 'h5';
      color = 'primary';
    } else if (isSummaryIndicatorNode) {
      typographyVariant = 'h6';
      color = 'primary';
    }
  }

  return (
    <AdmNodeRowTitleTooltip title={admNode.description}>
      <Typography variant={typographyVariant} color={color} sx={{ ...typographyStyles }}>
        {showAdmNumbers && (
          // <span style={{ ...nodeTitleNumberStyles, fontSize: 20 }}>{admNode.number && <span>{admNode.number}&nbsp;&nbsp;&nbsp;</span>}</span>
          <span style={{ ...nodeTitleNumberStyles }}>{admNode.number && <span>{admNode.number}&nbsp;&nbsp;&nbsp;</span>}</span>
        )}
        {/*<span style={{ fontSize: 20 }}>{admNode.name}</span>*/}
        <span>{admNode.name}</span>
      </Typography>
    </AdmNodeRowTitleTooltip>
  );
};

const HeatMapCell = ({ numerator, denominator, showHqimColumns, showContentColumns, showSummaryOnly, showRawData, styles, numPoints }) => {
  const value = denominator === 0 ? 0 : numerator / denominator;
  let percentage;

  // if numPoints != false, then this is a point scale question
  if (numPoints) {
    percentage = formatPercentage(value / numPoints);
  } else {
    percentage = formatPercentage(value);
  }

  const cellWidth = showSummaryOnly || !(showHqimColumns || showContentColumns) ? '40%' : '20%';
  const hasData = denominator > 0;
  const bgColor = hasData ? (numPoints ? getPointScaleColor(getPointBucketFromValue(value), numPoints) : getColor(percentage)) : '#cecece';

  return (
    <TableCell align="center" sx={nodeCellStyles(bgColor, cellWidth, styles)}>
      <Typography variant="h6">
        <strong>{hasData ? `${numPoints ? value.toFixed(1) : `${percentage}%`}` : '-'}</strong>
      </Typography>
      {showRawData && (
        <Typography
          variant="body2"
          component="div"
          sx={{
            position: 'absolute',
            right: 12,
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          {numPoints ? `${denominator}` : `(${numerator}/${denominator})`}
        </Typography>
      )}
    </TableCell>
  );
};

const AdmNodeRow = ({
  admNode,
  observations,
  showHqimColumns,
  showContentColumns,
  showFractions,
  showSummaryOnly,
  surveyTypeCategoryName,
  setDrawerAdmNode,
  showAdmNumbers,
}) => {
  if (!admNode.checked) {
    return null;
  }
  const isPointScale = isPointScaleQuestion(admNode);
  const numPoints = getNumPoints(admNode);

  const { allNodeResponses, allNodeResponsesByValue, col1NodeResponses, col1NodeResponsesByValue, col2NodeResponses, col2NodeResponsesByValue } =
    computeHeatMapValuesForAdmNode(admNode, observations, showHqimColumns, isPointScale, showSummaryOnly);

  const isTitle = isTitleNode(admNode?.survey_config);

  return (
    <TableRow>
      <TableCell sx={nodeTitleStyles} onClick={() => setDrawerAdmNode(admNode)}>
        <AdmNodeRowTitle admNode={admNode} surveyTypeCategoryName={surveyTypeCategoryName} showAdmNumbers={showAdmNumbers} />
      </TableCell>

      {isTitle ? (
        <TableCell align="center" sx={{ padding: '8px' }} colSpan={3}>
          &nbsp;
        </TableCell>
      ) : (
        <>
          {/* Render one PercentageCell for "ALL" if additional columns are not enabled */}
          {showSummaryOnly || (!showHqimColumns && !showContentColumns) ? (
            <HeatMapCell
              numerator={isPointScale ? allNodeResponsesByValue : allNodeResponsesByValue.length}
              denominator={allNodeResponses.length}
              showHqimColumns={showHqimColumns}
              showContentColumns={showContentColumns}
              showSummaryOnly={showSummaryOnly}
              showRawData={showFractions}
              styles={{ borderRight: 0 }}
              numPoints={numPoints}
            />
          ) : (
            <>
              {showHqimColumns && (
                <>
                  {/* Render all three cells when showHqimColumns is true */}
                  <HeatMapCell
                    numerator={isPointScale ? allNodeResponsesByValue : allNodeResponsesByValue.length}
                    denominator={allNodeResponses.length}
                    showHqimColumns={showHqimColumns}
                    showContentColumns={showContentColumns}
                    showRawData={showFractions}
                    styles={{ borderRight: '1px solid #e0e0e0' }}
                    numPoints={numPoints}
                  />
                  <HeatMapCell
                    numerator={isPointScale ? col1NodeResponsesByValue : col1NodeResponsesByValue.length}
                    denominator={col1NodeResponses.length}
                    showHqimColumns={showHqimColumns}
                    showContentColumns={showContentColumns}
                    showRawData={showFractions}
                    styles={{ borderRight: '1px solid #e0e0e0' }}
                    numPoints={numPoints}
                  />
                  <HeatMapCell
                    numerator={isPointScale ? col2NodeResponsesByValue : col2NodeResponsesByValue.length}
                    denominator={col2NodeResponses.length}
                    showHqimColumns={showHqimColumns}
                    showContentColumns={showContentColumns}
                    showRawData={showFractions}
                    styles={{ borderRight: 0 }}
                    numPoints={numPoints}
                  />
                </>
              )}
              {showContentColumns && (
                <>
                  {/* Render all three cells when showHqimColumns is true */}
                  <HeatMapCell
                    numerator={isPointScale ? allNodeResponsesByValue : allNodeResponsesByValue.length}
                    denominator={allNodeResponses.length}
                    showHqimColumns={showHqimColumns}
                    showContentColumns={showContentColumns}
                    showRawData={showFractions}
                    styles={{ borderRight: '1px solid #e0e0e0' }}
                    numPoints={numPoints}
                  />
                  <HeatMapCell
                    numerator={isPointScale ? col1NodeResponsesByValue : col1NodeResponsesByValue.length}
                    denominator={col1NodeResponses.length}
                    showHqimColumns={showHqimColumns}
                    showContentColumns={showContentColumns}
                    showRawData={showFractions}
                    styles={{ borderRight: '1px solid #e0e0e0' }}
                    numPoints={numPoints}
                  />
                  <HeatMapCell
                    numerator={isPointScale ? col2NodeResponsesByValue : col2NodeResponsesByValue.length}
                    denominator={col2NodeResponses.length}
                    showHqimColumns={showHqimColumns}
                    showContentColumns={showContentColumns}
                    showRawData={showFractions}
                    styles={{ borderRight: 0 }}
                    numPoints={numPoints}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </TableRow>
  );
};

AdmNodeRow.propTypes = {
  admNode: PropTypes.object.isRequired,
  observations: PropTypes.arrayOf(PropTypes.object),
  showHqimColumns: PropTypes.bool,
  showFractions: PropTypes.bool,
  surveyTypeCategoryName: PropTypes.string.isRequired,
  setDrawerAdmNode: PropTypes.func.isRequired,
};

export default AdmNodeRow;
