export const gradeRanges = ['K-2', '3-5', '6-8', '9-12'];

export const gradeLevelToNumber = (grade) => {
  if (grade === 'K') return 0;
  const match = grade.match(/\d+/);
  return match ? parseInt(match[0]) : null;
};

export const availableGradeRanges = (gradeLevels) => {
  return gradeRanges.filter((range) => {
    const [min, max] = range.split('-').map(gradeLevelToNumber);
    return gradeLevels.some((grade) => {
      const numGrade = gradeLevelToNumber(grade);
      return numGrade >= min && numGrade <= max;
    });
  });
};

export const isGradeInSelectedRanges = (grade, selectedGradeRanges) => {
  if (!grade || !selectedGradeRanges) return false;
  const numGrade = gradeLevelToNumber(grade);
  for (let range of selectedGradeRanges) {
    const [min, max] = range.split('-').map(gradeLevelToNumber);
    if (numGrade >= min && numGrade <= max) {
      return true;
    }
  }
  return false;
};

export const hqimInUseToNumber = (hqimInUse) => {
  if (hqimInUse === 'true') return 0;
  if (hqimInUse === 'false') return 1;
  return null;
};
