import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AdminSurveyItem from './admin_survey_item';
import { createApi } from '../../../apis/care/surveys/api';
import { Paper, Stack, Table, TableHead, TableBody, TableRow, TableCell, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useConfirmDialog } from '../../modals/confirm_dialog/';

const AdminCategorySection = (props) => {
  const api = createApi(props.baseUrl);
  const [surveys, setSurveys] = useState(props.surveyTypeCategory.surveys);
  const { showConfirmDialog, hideConfirmDialog } = useConfirmDialog();
  const canDelegate = props.canDelegate || false;
  const org_id = props.org_id;

  const handleCreate = () => {
    window.location.href = `${props.baseUrl}/new?survey_type_category=${props.surveyTypeCategory.id}`;
  };

  const handleClone = async (surveyId) => {
    try {
      const response = await api.clone(surveyId);
      window.location.href = response.redirect_to;
    } catch (error) {
      console.error(error);
    }
  };

  const confirmDeleteSurvey = (survey_id) => {
    showConfirmDialog({
      title: 'Delete Confirmation',
      description: 'Are you sure you want to delete this?',
      actions: [
        {
          label: 'Cancel',
          onClick: () => hideConfirmDialog(),
          variant: 'text',
          color: 'primary',
        },
        {
          label: 'Delete',
          onClick: () => handleDeleteSurvey(survey_id),
          variant: 'contained',
          color: 'primary',
          autoFocus: false,
        },
      ],
      maxWidth: 'xs',
    });
  };

  const handleDeleteSurvey = async (survey_id) => {
    try {
      await api.delete(survey_id);
      const updatedSurveys = surveys.filter((survey) => survey.id !== survey_id);
      setSurveys(updatedSurveys);
      hideConfirmDialog();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Paper className="p-3 mt-4" elevation={2} data-cy={props.surveyTypeCategory.name}>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <h5 className="blue-header">{props.surveyTypeCategory.name}</h5>
        {props.canCreate && (
          <Button
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faPlus} style={{ fontSize: '0.9em' }} />}
            onClick={handleCreate}
            data-cy="button-new-survey"
          >
            New {props.surveyTypeCategory.name}
          </Button>
        )}
      </Stack>
      <Table size={'small'} sx={{ marginTop: 4 }}>
        {surveys?.length > 0 ? (
          <>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '40%' }}>Name</TableCell>
                <TableCell sx={{ width: '15%' }}>Created by</TableCell>
                <TableCell sx={{ width: '15%' }}>Created on</TableCell>
                <TableCell sx={{ width: '10%' }}>Status</TableCell>
                <TableCell sx={{ width: '10%' }} align="center">
                  Observations
                </TableCell>
                <TableCell sx={{ width: '10%', alignItems: 'center', justifyContent: 'flex-end' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {surveys.map((survey) => (
                <AdminSurveyItem
                  key={survey.id}
                  survey={survey}
                  handleClone={handleClone}
                  handleDelete={() => confirmDeleteSurvey(survey.id)}
                  baseUrl={props.baseUrl}
                  baseUrlReports={props.baseUrlReports}
                  canDelegate={canDelegate}
                  org_id={org_id}
                />
              ))}
            </TableBody>
          </>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell
                colSpan={5}
                sx={{
                  width: '100%',
                  textAlign: 'center',
                  border: 0,
                  paddingTop: 0,
                  paddingBottom: 3,
                }}
              >
                <span className="text-muted">No items to display.</span>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </Paper>
  );
};

AdminCategorySection.propTypes = {
  baseUrl: PropTypes.string,
  canCreate: PropTypes.bool.isRequired,
  surveyTypeCategory: PropTypes.object.isRequired,
};

export default AdminCategorySection;
