import React, { useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Typography, Link, Checkbox, Button, Box, Stack } from '@mui/material';
import { ConfirmDialogProvider } from '../../modals/confirm_dialog';
import ObservationDialogAdapter from '../../../components/care/observations/observation_dialog_adapter';
import { startCase } from 'lodash';
import { OBSERVATION_TABLE_MODES } from './utils';
import { commonConfig } from '../../../helpers/data_grid/common_config';
import usePersistColumnSizes from '../../../helpers/data_grid/use_persisted_column_sizes';
import ResetColumnSizesMenu from '../../../helpers/data_grid/reset_column_sizes_menu';
import { multiLineConfig } from '../../../helpers/data_grid/multi_line_config';
import { gridStyles } from '../../../helpers/data_grid/common_grid_styles';
import { getObservation } from '../../../apis/care/observations/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartMixed } from '@fortawesome/pro-regular-svg-icons';
import Attachments from '../observations/attachments';

export default function ObservationTable({
  title,
  observations,
  baseUrl,
  observationsBaseUrl,
  observableGoalsBaseUrl,
  actionStepsBaseUrl,
  mode,
  leads,
  recipients,
  observableId,
  observer = null,
  canShowDataAnalysis = true,
  canShowCompare = true,
  canEditObservation = true,
}) {
  const [isObservationDialogOpen, setIsObservationDialogOpen] = useState(false);
  const [observation, setObservation] = useState(null);
  const [selectedObservationIds, setSelectedObservationIds] = useState([]);
  const isCoachMode = mode === OBSERVATION_TABLE_MODES.COACH;
  const isObservableMode = mode === OBSERVATION_TABLE_MODES.OBSERVABLE;

  const toggleSelectedObservation = (observation) => {
    if (selectedObservationIds.includes(observation.id)) {
      setSelectedObservationIds(selectedObservationIds.filter((id) => id !== observation.id));
    } else {
      setSelectedObservationIds([...selectedObservationIds, observation.id]);
    }
  };

  const handleObservationIdClick = async (event, observation) => {
    event.preventDefault();
    const observationData = await getObservation(observation.id);
    setObservation(observationData);
    setIsObservationDialogOpen(true);
  };

  const handleObservationDialogClose = () => {
    setObservation(null);
    setIsObservationDialogOpen(false);
  };

  const handleViewAllObservations = (event) => {
    event.preventDefault();
    const orgId = baseUrl.match(/orgs\/(\d+)/)[1];

    if (isObservableMode) {
      window.location.href = `/orgs/${orgId}/care/reports/observations?observable_id=${observableId}`;
    } else if (isCoachMode) {
      window.location.href = `/orgs/${orgId}/care/reports/observations?observer_id=${observer.id}`;
    }
  };

  const handleCompareObservations = (event) => {
    event.preventDefault();
    const orgId = baseUrl.match(/orgs\/(\d+)/)[1];
    window.location.href = `/orgs/${orgId}/care/reports/observations?observation_ids=${selectedObservationIds}`;
  };

  const ObserverOrSchoolColumn = isObservableMode
    ? {
        field: 'observer',
        headerName: 'Observer',
        flex: 0.3,
        valueFormatter: (value) => value.full_name,
      }
    : {
        field: 'school_name',
        headerName: 'School',
        flex: 0.3,
        valueFormatter: (value) => value?.name,
      };

  const columns = [
    {
      field: 'observation_date',
      headerName: 'Date',
      valueFormatter: (value) => new Date(value).toLocaleDateString({}, { timeZone: 'UTC' }),
    },
    {
      field: 'observation_id',
      headerName: 'Observation ID',
      renderCell: (o) => <Link onClick={(e) => handleObservationIdClick(e, o.row)}>{o.id}</Link>,
    },

    ObserverOrSchoolColumn,

    ...(isObservableMode
      ? []
      : [
          {
            field: 'observable',
            headerName: 'Teacher',
            valueFormatter: (value) => value?.name,
          },
        ]),
    {
      field: 'survey_name',
      headerName: 'Template',
      flex: 0.5,
    },
    {
      field: 'grade_level',
      headerName: 'Grade',
    },
    {
      field: 'content_area',
      headerName: 'Content',
      valueFormatter: (value) => value?.name,
    },
    {
      field: 'status',
      headerName: 'Status',
      valueFormatter: (value) => startCase(value),
    },
    {
      field: 'attachments',
      headerName: 'Resources',
      valueFormatter: (value) => value || '',
      renderCell: (params) => <Attachments observation={params.row} hideTitle={true} hideUpload={true} />,
      align: 'center',
      headerAlign: 'center',
    },

    ...(canShowCompare
      ? [
          {
            field: 'compare',
            headerName: 'Compare',
            renderCell: (observation) => <Checkbox onClick={(e) => toggleSelectedObservation(observation)} />,
            flex: 0.1,
            align: 'right',
            headerAlign: 'right',
            renderHeader: () => (
              <Button
                variant="contained"
                color="primary"
                size="small"
                disabled={selectedObservationIds.length < 2}
                sx={{ mr: 0 }}
                onClick={handleCompareObservations}
              >
                Compare
              </Button>
            ),
          },
        ]
      : []),
  ];

  const [adjustedColumns, handleColumnResize, resetColumnWidths] = usePersistColumnSizes('observableDashObservations', columns);

  return (
    <Box sx={{ marginTop: 5 }}>
      {isObservationDialogOpen && (
        <ConfirmDialogProvider>
          <ObservationDialogAdapter
            survey={observation.survey}
            observation={observation}
            baseUrl={observationsBaseUrl}
            observableGoalsBaseUrl={observableGoalsBaseUrl}
            actionStepsBaseUrl={actionStepsBaseUrl}
            onClose={handleObservationDialogClose}
            onDelete={(e) => {
              e.preventDefault();
            }}
            leads={leads}
            recipients={recipients}
            readOnly={!canEditObservation}
          />
        </ConfirmDialogProvider>
      )}
      <Stack direction="row" alignItems={'center'} justifyContent={'space-between'} spacing={2} sx={{ mb: 2 }}>
        <Typography variant="h6" className="blue-header" sx={{ mb: 2 }}>
          {title || 'Observations'}
        </Typography>
        {(isObservableMode || isCoachMode) && (
          <Button variant="contained" color={'secondary'} onClick={handleViewAllObservations} startIcon={<FontAwesomeIcon icon={faChartMixed} />}>
            Data Analysis
          </Button>
        )}
      </Stack>
      <DataGridPro
        rows={observations}
        columns={adjustedColumns}
        onColumnResize={handleColumnResize}
        sx={gridStyles}
        slots={{
          columnMenu: ResetColumnSizesMenu,
        }}
        slotProps={{
          columnMenu: {
            columnProps: {
              onResetColumnSizes: () => resetColumnWidths(),
            },
          },
        }}
        {...commonConfig({ disableColumnResize: false })}
        {...multiLineConfig}
      />
    </Box>
  );
}
