import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Layout from '../../layouts/application';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../../constants/theme';
import { Grid, Typography, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons';
import Content from '../../../components/care/reports/compare/content';
import Navigation from '../../../components/care/reports/compare/navigation';
import {
  CHART_TYPES,
  NAV_TYPES,
  mergeAdmTrees,
  mergeObservations,
  filterAdmTrees,
  isAllNodesPointScale,
} from '../../../components/care/reports/compare/utils';
import CareNav from '../../../components/care/shared/nav';

import { getMetaDataChartConfig, getAdmTreeChartConfig } from '../../../components/care/reports/compare/chart_config';
import {
  isHqimAnalysisIncluded,
  isRacialDiversityAnalysisIncluded,
  isShowAdmNumbers,
  hideClassGroupingData,
} from '../../../components/care/shared/utils';

const Container = (props) => {
  const { surveys } = props;
  surveys.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

  const showHqim = useMemo(() => {
    return surveys.every(isHqimAnalysisIncluded);
  }, [surveys]);

  const showPredominantlyPoc = useMemo(() => {
    return surveys.every(isRacialDiversityAnalysisIncluded);
  }, [surveys]);

  const showAdmNumbers = useMemo(() => {
    return surveys.every(isShowAdmNumbers);
  }, [surveys]);

  const hideClassRoomEngagement = useMemo(() => {
    return surveys.every((survey) => hideClassGroupingData(survey));
  }, [surveys]);

  const admTrees = useMemo(() => {
    return mergeAdmTrees(surveys.map((survey) => survey.adm_tree));
  }, [surveys]);

  const isPointScaleComparison = useMemo(() => {
    return isAllNodesPointScale(admTrees);
  }, [surveys]);

  const [selectedNodes, setSelectedNodes] = useState([]);

  const [chartType, setChartType] = useState(CHART_TYPES.BAR);

  const [groupBy, setGroupBy] = useState('default');

  const getDefaultNavType = () => {
    if (hideClassRoomEngagement) {
      return NAV_TYPES.INDICATORS;
    } else {
      return NAV_TYPES.STUDENTS_ENG_IN_CLASS;
    }
  };

  const getDefaultChartConfig = () => {
    if (hideClassRoomEngagement) {
      return getAdmTreeChartConfig(surveys, selectedNodes, chartType, groupBy, isPointScaleComparison);
    } else {
      return getMetaDataChartConfig(surveys, selectedNav, chartType, groupBy);
    }
  };

  const defaultNavType = getDefaultNavType();
  const [selectedNav, setSelectedNav] = useState(defaultNavType);
  const [chartConfig, setChartConfig] = useState(() => getDefaultChartConfig());

  const filteredAdmTrees = useMemo(() => {
    if (selectedNodes.length > 0) {
      return filterAdmTrees(admTrees, selectedNodes);
    } else {
      return admTrees;
    }
  }, [selectedNodes, admTrees]);

  const combinedObservations = useMemo(() => {
    return mergeObservations(surveys.map((survey) => survey.observations));
  }, [surveys]);

  useEffect(() => {
    let config;
    if (selectedNav === NAV_TYPES.INDICATORS) {
      config = getAdmTreeChartConfig(surveys, selectedNodes, chartType, groupBy, isPointScaleComparison);
    } else {
      config = getMetaDataChartConfig(surveys, selectedNav, chartType, groupBy);
    }
    setChartConfig(config);
  }, [chartType, selectedNav, surveys, groupBy, selectedNodes]);

  const handleNavigationChange = (nav, selectedNodes = []) => {
    setSelectedNav(nav);
    setSelectedNodes(selectedNodes);

    if (nav === NAV_TYPES.INDICATORS && selectedNodes.length === 0) {
      setChartConfig(getAdmTreeChartConfig(surveys, selectedNodes, chartType, groupBy, isPointScaleComparison));
    }
  };

  const handleChartTypeChange = (chartType) => {
    setChartType(chartType);
  };

  const handleGroupByChange = (groupBy) => {
    setGroupBy(groupBy);
  };

  return (
    <div className="mt-2 care-comparison">
      <CareNav {...props} back_url={''} />
      <Grid container columnSpacing={{ xs: 4 }} wrap="wrap" marginTop={3}>
        <Grid item xs={12} md={3} sx={{ minWidth: '250px !important', maxWidth: '300px !important' }}>
          <Button
            onClick={() => (window.location.href = props.back_url)}
            startIcon={<FontAwesomeIcon icon={faAngleLeft} color="#67bbb9" />}
            sx={{ color: '#0C2C57' }}
            className="no-print"
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="h4" sx={{ color: '#0C2C57' }}>
            Template Comparison
          </Typography>
        </Grid>
      </Grid>
      <Grid container columnSpacing={{ xs: 2, md: 2 }} wrap="wrap" marginTop={2}>
        <Grid
          item
          xs={12}
          md={3}
          className="no-print"
          sx={{
            minWidth: '250px !important',
            maxWidth: '300px !important',
            position: { xs: 'relative', md: 'sticky' },
            top: { md: '100px' },
            height: '100%',
          }}
        >
          <Navigation
            admTrees={admTrees}
            selectedNav={selectedNav}
            selectedNodes={selectedNodes}
            onNavigationChange={handleNavigationChange}
            showHqim={showHqim}
            showPredominantlyPoc={showPredominantlyPoc}
            hideClassRoomEngagement={hideClassRoomEngagement}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <Content
            surveys={surveys}
            chartConfig={chartConfig}
            chartType={chartType}
            groupBy={groupBy}
            onChartTypeChange={handleChartTypeChange}
            onGroupByChange={handleGroupByChange}
            admTrees={filteredAdmTrees}
            combinedObservations={combinedObservations}
            showHqim={showHqim}
            showPredominantlyPoc={showPredominantlyPoc}
            showAdmNumbers={showAdmNumbers}
            selectedNav={selectedNav}
            isPointScaleComparison={isPointScaleComparison}
            hideClassRoomEngagement={hideClassRoomEngagement}
          />
        </Grid>
      </Grid>
    </div>
  );
};

Container.propTypes = {
  back_url: PropTypes.string,
  base_url: PropTypes.string.isRequired,
  surveys: PropTypes.array.isRequired,
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <Container {...props}></Container>
      </ThemeProvider>
    </Layout>
  );
};
