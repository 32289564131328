import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionSummary, AccordionDetails, FormControlLabel, Checkbox, TextField, Typography, Tabs, Tab, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const accordionStyles = {
  root: {
    minWidth: '180px',
    color: '#333',
    marginTop: '0',
    marginBottom: '0',
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      marginTop: '0 !important',
      marginBottom: '0 !important',
      '&:first-of-type': {
        marginTop: '0 !important',
        marginBottom: '0 !important',
      },
    },
  },
  summary: {
    backgroundColor: '#FFFFFF',
    '&.Mui-expanded': {
      marginTop: '0 !important',
    },
  },
};

const getItemId = (item) => {
  return item.id || item;
};

const FilterAccordion = ({
  prop_name,
  title,
  renderLabel,
  items,
  selectedItems,
  isSearchable,
  searchText,
  onSearchChange,
  onFilterChange,
  onClearAll,
  onSelectAll,
  hasTabs = false,
  gradeRanges,
  onGradeRangeFilterChange,
  selectedGradeRanges,
}) => {
  const [activeTab, setActiveTab] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const tabStyles = {
    typography: {
      textTransform: 'none',
      fontSize: '0.85rem',
    },
  };

  const RenderSelectOrClearAll = () => {
    return (
      <div style={{ marginTop: '.7rem' }}>
        {selectedItems.size > 0 ? (
          <a className="link-dark-no-underline d-inline-flex" onClick={() => onClearAll(prop_name)}>
            <Typography variant="body2">Clear All</Typography>
          </a>
        ) : (
          <a className="link-dark-no-underline d-inline-flex" onClick={() => onSelectAll(items, onFilterChange)}>
            <Typography variant="body2">Select All</Typography>
          </a>
        )}
      </div>
    );
  };

  const renderIndividualGrades = () => {
    const gridColumnSize = hasTabs ? 6 : 12;
    return (
      <>
        <RenderSelectOrClearAll />
        <Grid container spacing={0}>
          {items.map((item) => (
            <Grid item xs={gridColumnSize} key={getItemId(item)}>
              <FormControlLabel
                key={getItemId(item)}
                control={
                  <Checkbox
                    sx={{ marginTop: '-9px' }}
                    checked={selectedItems.has(getItemId(item))}
                    onChange={() => onFilterChange(getItemId(item))}
                  />
                }
                label={<Typography variant="body2">{renderLabel(item)}</Typography>}
                labelPlacement="end"
                sx={{ alignItems: 'flex-start', paddingTop: 1 }}
              />
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  const renderGradeRanges = () => {
    return (
      <>
        <Grid container spacing={0}>
          {gradeRanges.map((range) => (
            <Grid item xs={6} key={range}>
              <FormControlLabel
                control={
                  <Checkbox sx={{ marginTop: '-9px' }} checked={selectedGradeRanges.has(range)} onChange={() => onGradeRangeFilterChange(range)} />
                }
                label={<Typography variant="body2">{range}</Typography>}
                labelPlacement="end"
                sx={{ alignItems: 'flex-start', paddingTop: 1 }}
              />
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  return (
    <Accordion sx={accordionStyles.root}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: '#67bbb9' }} />}
        aria-controls={`${prop_name}-content`}
        id={`${prop_name}-header`}
        sx={accordionStyles.summary}
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {isSearchable && (
          <TextField
            placeholder="Search"
            value={searchText}
            onChange={onSearchChange}
            fullWidth
            margin="normal"
            variant="outlined"
            size="small"
            sx={{ marginBottom: '1rem', marginTop: '0 !important' }}
            InputProps={{
              style: {
                backgroundColor: 'white',
                border: 'none',
              },
            }}
          />
        )}
        {hasTabs ? (
          <>
            <Tabs value={activeTab} onChange={handleTabChange} variant="fullWidth" aria-label="Grade Level Tabs">
              <Tab label="Individual" sx={tabStyles.typography} />
              <Tab label="Ranges" sx={tabStyles.typography} />
            </Tabs>
            {activeTab === 0 ? renderIndividualGrades() : renderGradeRanges()}
          </>
        ) : (
          renderIndividualGrades()
        )}
      </AccordionDetails>
    </Accordion>
  );
};

FilterAccordion.propTypes = {
  prop_name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  renderLabel: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  selectedItems: PropTypes.instanceOf(Set).isRequired,
  isSearchable: PropTypes.bool,
  searchText: PropTypes.string,
  onSearchChange: PropTypes.func,
  onFilterChange: PropTypes.func,
  onClearAll: PropTypes.func,
  onSelectAll: PropTypes.func,
};

export default FilterAccordion;
