import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import { grades } from '../../../../constants/grades';
import { diversityOptions } from '../../../../constants/diversity_options';
import { useSearchableFilter } from './use_searchable_filter';
import FilterAccordion from './filter_accordion';
import { availableGradeRanges } from './utils';
import { isHqimAnalysisIncluded, isRacialDiversityAnalysisIncluded, isReportDisplaySummaryOnly } from '../../shared/utils';

// For property objects having an id, extract the unique objects and sort them
const extractUniqueAndSort = (surveys, observations, surveyProp, observerProp) => {
  const surveyPropIds = surveys.map((survey) => survey[surveyProp].map((item) => item.id)).flat();
  const sortedIds = [...new Set(surveyPropIds)];

  return [
    ...new Map(
      observations.filter((o) => o[observerProp] !== null && o[observerProp] !== undefined).map((o) => [o[observerProp].id, o[observerProp]])
    ).values(),
  ].sort((a, b) => sortedIds.indexOf(a.id) - sortedIds.indexOf(b.id));
};

// Grade levels are stored as strings
const extractGradeLevels = (observations) => {
  return [...new Map(observations.map((o) => [o.grade_level, o.grade_level])).values()]
    .filter((n) => grades.includes(n))
    .sort((a, b) => grades.indexOf(a) - grades.indexOf(b));
};

const extractDiversityOptions = (observations) => {
  return [...new Map(observations.map((o) => [o.diverse_classroom, o.diverse_classroom])).values()]
    .filter((n) => diversityOptions.includes(n))
    .sort((a, b) => diversityOptions.indexOf(a) - diversityOptions.indexOf(b));
};

// Observers are not included in the survey object
const extractObservers = (observations) => {
  return [...new Map(observations.map((o) => [o.observer.id, o.observer])).values()];
};

const ShowFilters = ({
  surveys,
  observations,
  selectedSchools,
  selectedObservables,
  selectedObservers,
  selectedGradeLevels,
  selectedGradeRanges,
  selectedContentAreas,
  selectedInstructionLanguages,
  selectedDiversityOptions,
  onSchoolFilterChange,
  onObservableFilterChange,
  onObserverFilterChange,
  onGradeLevelFilterChange,
  onGradeRangeFilterChange,
  onContentAreaFilterChange,
  onInstructionLanguageFilterChange,
  onDiversityOptionFilterChange,
  isFilterActive,
  onClearFilters,
  hideClassRoomEngagement,
}) => {
  // prep data
  let schoolNames = extractUniqueAndSort(surveys, observations, 'school_names', 'school_name');
  let contentAreas = extractUniqueAndSort(surveys, observations, 'content_areas', 'content_area');
  let observables = extractUniqueAndSort(surveys, observations, 'observables', 'observable');
  let instructionLanguages = extractUniqueAndSort(surveys, observations, 'instruction_languages', 'instruction_language');
  let gradeLevels = extractGradeLevels(observations);
  let diversityOptions = extractDiversityOptions(observations);
  let observers = extractObservers(observations);

  const showDiversity = surveys.every(isRacialDiversityAnalysisIncluded);
  const showContent = surveys.every((survey) => isHqimAnalysisIncluded(survey) || isReportDisplaySummaryOnly(survey));

  // prep hooks
  const {
    searchText: schoolSearchText,
    handleSearchChange: handleSchoolSearchChange,
    filteredItems: filteredSchoolNames,
  } = useSearchableFilter(schoolNames, 'name', selectedSchools);

  const {
    searchText: observableSearchText,
    handleSearchChange: handleObservableSearchChange,
    filteredItems: filteredObservables,
  } = useSearchableFilter(observables, 'name', selectedObservables);

  const {
    searchText: contentSearchText,
    handleSearchChange: handleContentSearchChange,
    filteredItems: filteredContentAreas,
  } = useSearchableFilter(contentAreas, 'name', selectedContentAreas);

  const {
    searchText: observerSearchText,
    handleSearchChange: handleObserverSearchChange,
    filteredItems: filteredObservers,
  } = useSearchableFilter(observers, 'full_name', selectedObservers);

  const handleSelectAll = (items, changeHandler) => {
    const itemIds = items.map((item) => {
      // Check if the item is an object and has an 'id' property
      return typeof item === 'object' && item.id ? item.id : item;
    });
    changeHandler(itemIds);
  };

  const gradeRanges = availableGradeRanges(gradeLevels);

  const observableTypes = new Set(observables.map((o) => o.observable_type.name));

  return (
    <>
      <div className="filter-by-header">
        <Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap">
          <Typography variant="body1">
            <strong>Filter by</strong>
          </Typography>
          {isFilterActive && (
            <a className="link-dark-no-underline d-inline-flex" onClick={() => onClearFilters('')}>
              <Typography variant="body2" color="#009A96">
                Clear All
              </Typography>
            </a>
          )}
        </Stack>
      </div>
      {schoolNames.length > 0 && (
        <FilterAccordion
          prop_name="school_name"
          title="School Name"
          items={filteredSchoolNames}
          selectedItems={selectedSchools}
          isSearchable={schoolNames.length > 1}
          searchText={schoolSearchText}
          onSearchChange={handleSchoolSearchChange}
          onFilterChange={onSchoolFilterChange}
          onSelectAll={handleSelectAll}
          onClearAll={onClearFilters}
          renderLabel={(item) => item.name}
        />
      )}
      {gradeLevels.length > 0 && (
        <FilterAccordion
          prop_name="grade_level"
          title="Grade"
          items={gradeLevels}
          selectedItems={selectedGradeLevels}
          isSearchable={false}
          onFilterChange={onGradeLevelFilterChange}
          onSelectAll={handleSelectAll}
          onClearAll={onClearFilters}
          renderLabel={(item) => item}
          hasTabs={true}
          selectedGradeRanges={selectedGradeRanges}
          gradeRanges={gradeRanges}
          onGradeRangeFilterChange={onGradeRangeFilterChange}
        />
      )}
      {contentAreas.length > 0 && showContent && (
        <FilterAccordion
          prop_name="content_area"
          title="Content"
          items={filteredContentAreas}
          selectedItems={selectedContentAreas}
          isSearchable={contentAreas.length > 1}
          searchText={contentSearchText}
          onSearchChange={handleContentSearchChange}
          onFilterChange={onContentAreaFilterChange}
          onSelectAll={handleSelectAll}
          onClearAll={onClearFilters}
          renderLabel={(item) => item.name}
        />
      )}
      {observables.length > 0 && (
        <FilterAccordion
          prop_name="observable"
          title={observableTypes.size === 1 ? observableTypes.values().next().value : 'Observee'}
          items={filteredObservables}
          selectedItems={selectedObservables}
          isSearchable={observables.length > 1}
          searchText={observableSearchText}
          onSearchChange={handleObservableSearchChange}
          onFilterChange={onObservableFilterChange}
          onSelectAll={handleSelectAll}
          onClearAll={onClearFilters}
          renderLabel={(item) => item.name}
        />
      )}
      {instructionLanguages.length > 0 && (
        <FilterAccordion
          prop_name="instruction_language"
          title="Instruction Language"
          items={instructionLanguages}
          selectedItems={selectedInstructionLanguages}
          isSearchable={false}
          onFilterChange={onInstructionLanguageFilterChange}
          onSelectAll={handleSelectAll}
          onClearAll={onClearFilters}
          renderLabel={(item) => item.name}
        />
      )}
      {diversityOptions.length > 0 && showDiversity && (
        <FilterAccordion
          prop_name="diverse_classroom"
          title="Diversity"
          items={diversityOptions}
          selectedItems={selectedDiversityOptions}
          isSearchable={false}
          onFilterChange={onDiversityOptionFilterChange}
          onSelectAll={handleSelectAll}
          onClearAll={onClearFilters}
          renderLabel={(item) => item}
        />
      )}

      {observers.length > 0 && (
        <FilterAccordion
          prop_name="observer"
          title="Observer"
          items={filteredObservers}
          selectedItems={selectedObservers}
          isSearchable={observers.length > 1}
          searchText={observerSearchText}
          onSearchChange={handleObserverSearchChange}
          onFilterChange={onObserverFilterChange}
          onSelectAll={handleSelectAll}
          onClearAll={onClearFilters}
          renderLabel={(item) => item.full_name}
        />
      )}
    </>
  );
};

ShowFilters.propTypes = {
  surveys: PropTypes.array.isRequired,
  observations: PropTypes.array.isRequired,
  onSchoolFilterChange: PropTypes.func.isRequired,
  onObservableFilterChange: PropTypes.func.isRequired,
  onObserverFilterChange: PropTypes.func.isRequired,
  onGradeLevelFilterChange: PropTypes.func.isRequired,
  onContentAreaFilterChange: PropTypes.func.isRequired,
  onInstructionLanguageFilterChange: PropTypes.func.isRequired,
  onDiversityOptionFilterChange: PropTypes.func.isRequired,
  selectedSchools: PropTypes.object,
  selectedObservables: PropTypes.object,
  selectedObservers: PropTypes.object,
  selectedGradeLevels: PropTypes.object,
  selectedGradeRanges: PropTypes.object,
  selectedContentAreas: PropTypes.object,
  selectedInstructionLanguages: PropTypes.object,
  selectedDiversityOptions: PropTypes.object,
  isFilterActive: PropTypes.bool,
  onClearFilters: PropTypes.func,
};

export default ShowFilters;
