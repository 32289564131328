import React, { useState } from 'react';
import Layout from '../../layouts/application';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../../constants/theme';
import { createApi as createSurveyApi } from '../../../apis/care/surveys/api';
import { getSurvey } from '../../../apis/care/surveys/api';
import { getObservation, deleteObservation, createObservation } from '../../../apis/care/observations/api';
import Form from '../../../components/care/surveys/form';
import ObservationDialogAdapter from '../../../components/care/observations/observation_dialog_adapter';
import useWarnOnUnsavedChanges from '../../../helpers/use_warn_on_unsaved_changes';
import { ConfirmDialogProvider, useConfirmDialog } from '../../../components/modals/confirm_dialog';
import CareNav from '../../../components/care/shared/nav';

const Container = (props) => {
  const surveyApi = createSurveyApi(props.base_url);
  const { showConfirmDialog, hideConfirmDialog } = useConfirmDialog();

  const [survey, setSurvey] = React.useState(props.survey || null);
  const [activeObservations, setActiveObservations] = useState(props.survey?.observations || []);
  const [observation, setObservation] = React.useState(null);
  const [isObservationDialogOpen, setIsObservationDialogOpen] = useState(false);
  const [, setHasUnsavedChanges] = useWarnOnUnsavedChanges();

  const refreshSurvey = async () => {
    if (survey?.id) {
      const surveyResponse = await getSurvey(survey.id);
      const updatedObservations = [...surveyResponse.observations];
      setSurvey({ ...surveyResponse, observations: updatedObservations });
    }
  };

  const editSurvey = async (survey) => {
    const response = await surveyApi.update(props.survey.id, survey);
    window.location.href = response.redirect_to;
  };

  const confirmDeleteSurvey = () => {
    showConfirmDialog({
      title: 'Delete Confirmation',
      description: 'Are you sure you want to delete this?',
      actions: [
        {
          label: 'Cancel',
          onClick: () => hideConfirmDialog(),
          variant: 'text',
          color: 'primary',
        },
        {
          label: 'Delete',
          onClick: handleDeleteSurvey,
          variant: 'contained',
          color: 'primary',
          autoFocus: false,
        },
      ],
      maxWidth: 'xs',
    });
  };

  const handleDeleteSurvey = async () => {
    try {
      await surveyApi.delete(props.survey.id);
      hideConfirmDialog();
      window.location.href = props.base_url;
    } catch (error) {
      console.error(error);
    }
  };

  const [isCreatingObservation, setIsCreatingObservation] = useState(false);

  const handleCreateObservation = async () => {
    if (isCreatingObservation) return;

    try {
      setIsCreatingObservation(true);
      let observationsBaseUrl = `${props.base_url}/${props.survey.id}/observations`;
      const newObservation = await createObservation({ observation: {} }, observationsBaseUrl);
      setObservation(newObservation);
      setIsObservationDialogOpen(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsCreatingObservation(false);
    }
  };

  const handleCloseObservation = async (newObservation) => {
    setIsObservationDialogOpen(false);

    if (newObservation?.id) {
      await refreshSurvey();
      setActiveObservations((prevObservations) => {
        const observationIndex = activeObservations.findIndex((observation) => observation.id === newObservation.id);
        if (observationIndex > -1) {
          const updatedObservations = [...prevObservations];
          updatedObservations[observationIndex] = newObservation;
          return updatedObservations;
        } else {
          return [...prevObservations, newObservation];
        }
      });
    }
  };

  const handleEditObservation = async (surveyId, observationId) => {
    try {
      const response = await getObservation(observationId);
      setObservation(response);
      setIsObservationDialogOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteObservation = async (surveyId, observationId) => {
    try {
      await deleteObservation(observationId);

      const observationIdAsNumber = Number(observationId);
      const updatedObservations = [...activeObservations.filter((obs) => obs.id !== observationIdAsNumber)];
      setActiveObservations(updatedObservations);
      await refreshSurvey();
      hideConfirmDialog();
    } catch (error) {
      console.error(error);
    }
  };

  const confirmDeleteObservation = (surveyId, observationId) => {
    showConfirmDialog({
      title: 'Delete Confirmation',
      description: 'Are you sure you want to delete this Observation?',
      actions: [
        {
          label: 'Cancel',
          onClick: () => hideConfirmDialog(),
          variant: 'text',
          color: 'primary',
        },
        {
          label: 'Delete',
          onClick: () => handleDeleteObservation(surveyId, observationId),
          variant: 'contained',
          color: 'primary',
          autoFocus: false,
        },
      ],
      maxWidth: 'xs',
    });
  };

  const goals_url = props.page_props.goals.url;
  const action_steps_url = props.page_props.action_steps.url;

  return (
    <>
      <CareNav {...props} back_url={props.base_url} />
      <Form
        onSave={editSurvey}
        onDelete={confirmDeleteSurvey}
        onCreateObservation={handleCreateObservation}
        isCreatingObservation={isCreatingObservation}
        onUnsavedChangesChanged={setHasUnsavedChanges}
        observationCount={survey?.observations?.length || 0}
        algoliaAppId={props.algoliaAppId}
        algoliaSearchKey={props.algoliaSearchKey}
        survey={survey}
        activeObservations={activeObservations}
        observations_key={JSON.stringify(activeObservations)}
        onEditObservation={handleEditObservation}
        onDeleteObservation={confirmDeleteObservation}
        {...props}
      />
      {isObservationDialogOpen && (
        <ObservationDialogAdapter
          survey={props.survey}
          observation={observation}
          baseUrl={props.base_url}
          observableGoalsBaseUrl={goals_url}
          actionStepsBaseUrl={action_steps_url}
          onClose={handleCloseObservation}
          leads={props.leads}
          recipients={props.recipients}
        />
      )}
    </>
  );
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <ConfirmDialogProvider>
          <Container {...props}></Container>
        </ConfirmDialogProvider>
      </ThemeProvider>
    </Layout>
  );
};
