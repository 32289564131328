import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tooltip, Typography, Zoom } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { getColor, getPointScaleColor } from '../heatmaps/heatmap_colors';
import { getPointBucketFromValue } from '../heatmaps/utils';
import { isTitleNode, isAspect, isSummaryIndicator, formatPercentage, hasAtLeastOneCheckedNode, groupObservationsBy } from '../heatmaps/utils';
import { isPointScaleQuestion, getNumPoints } from '../../shared/utils';
import {
  observationsWithResponseForAdmNode,
  observationsWithResponseForAdmNodeByValue,
  sumResponsesByAdmNode,
} from '../observation_adm_node_filter_utils';
import { smartCrop } from '../../../../helpers/string_utils';

import {
  tableContainerStyles,
  tableStyles,
  headerContainerStyles,
  headerTitleStyles,
  headerCellStyles,
  nodeTitleStyles,
  nodeTitleNumberStyles,
  nodeCellStyles,
} from '../heatmaps/styles';

const AdmNodeRowTitleTooltip = ({ title, children, ...props }) => {
  return (
    <Tooltip TransitionComponent={Zoom} placement="top" enterDelay={300} enterNextDelay={300} arrow={true} title={title} {...props}>
      <div>{children}</div>
    </Tooltip>
  );
};

const TableHeader = ({ surveyNames, groupName, groupIndex, groupBy }) => {
  return (
    <>
      <Box sx={headerContainerStyles}>
        <Table style={{ tableLayout: 'fixed', width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ ...headerTitleStyles }} colSpan="1">
                <Box sx={{ position: 'relative', left: '-24px' }}>
                  <Typography variant="h6" color="primary">
                    {groupName !== 'default' && <strong>{groupName}</strong>}
                  </Typography>
                </Box>
              </TableCell>
              {groupIndex === 0 ? (
                <>
                  {surveyNames.map((surveyName, index) => (
                    <TableCell sx={{ ...headerCellStyles('20%') }} key={index}>
                      <AdmNodeRowTitleTooltip title={surveyName} key={index}>
                        <div
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            // textOverflow: 'ellipsis',
                            paddingLeft: '8px',
                            paddingRight: '8px',
                          }}
                        >
                          {smartCrop(surveyName, {
                            maxLength: 16,
                            ellipsisPosition: 'head',
                          })}
                        </div>
                      </AdmNodeRowTitleTooltip>
                    </TableCell>
                  ))}
                </>
              ) : (
                <>
                  <TableCell sx={{ borderBottom: '0', padding: '8px 4px' }} colSpan="3">
                    &nbsp;
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
        </Table>
      </Box>
    </>
  );
};

const ResponseCell = ({ title, numerator, denominator, isPointScale, numPoints }) => {
  const value = denominator === 0 ? 0 : numerator / denominator;
  const percentage = formatPercentage(value);
  const hasData = denominator > 0;
  const bgColor = hasData ? (isPointScale ? getPointScaleColor(getPointBucketFromValue(value), numPoints) : getColor(percentage)) : '#cecece';

  return title ? null : (
    <TableCell align="center" sx={nodeCellStyles(bgColor, '20%', { borderRight: '1px solid #e0e0e0' })}>
      <Typography variant="h6">
        <strong>{hasData ? `${isPointScale ? value.toFixed(1) : `${percentage}%`}` : '-'}</strong>
      </Typography>
    </TableCell>
  );
};

const AdmNodeRowTitle = ({ node, showAdmNumbers }) => {
  const isAspectNode = isAspect(node.type);
  const isSummaryIndicatorNode = isSummaryIndicator(node.type);

  const typographyStyles = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  };

  let typographyVariant = 'body1';
  let color = 'inherit';

  if (isAspectNode) {
    typographyVariant = 'h5';
    color = 'primary';
  }
  if (isSummaryIndicatorNode) {
    typographyVariant = 'h6';
    color = 'primary';
  }

  return (
    <Typography variant={typographyVariant} color={color} sx={{ ...typographyStyles }}>
      {showAdmNumbers && <span style={{ ...nodeTitleNumberStyles }}>{node.number && <span>{node.number}&nbsp;&nbsp;&nbsp;</span>}</span>}
      <span>{node.name}</span>
    </Typography>
  );
};

const AdmNode = ({ node, surveys, observations, showHqim, showAdmNumbers }) => {
  if (!node.checked) {
    return null;
  }

  // const isTitle = isTitleNode(node?.survey_config);
  const isPointScale = isPointScaleQuestion(node);
  const numPoints = getNumPoints(node);
  const surveyData = surveys.map((survey) => {
    const surveyObservations = observations.filter((o) => o.survey_id === survey.id);
    const total = observationsWithResponseForAdmNode(surveyObservations, node);
    let yes;

    // LDOE DEMO
    if (isPointScale) {
      yes = sumResponsesByAdmNode(surveyObservations, node);
    } else {
      yes = observationsWithResponseForAdmNodeByValue(surveyObservations, node, 'yes');
    }

    return {
      surveyId: survey.id,
      surveyName: survey.name,
      totalCount: total.length,
      yes: yes,
    };
  });

  return (
    <TableRow>
      <TableCell sx={nodeTitleStyles}>
        <AdmNodeRowTitle node={node} showAdmNumbers={showAdmNumbers} />
      </TableCell>
      {surveyData.map((data, index) => (
        <ResponseCell
          title={node.survey_config.question_type === 'title'}
          numerator={isPointScale ? data?.yes : data?.yes?.length}
          denominator={data.totalCount}
          isPointScale={isPointScale}
          numPoints={numPoints}
          key={index}
        />
      ))}
    </TableRow>
  );
};

const Indicator = ({ indicator, surveys, observations, showAdmNumbers }) => {
  return (
    <>
      <AdmNode node={indicator} surveys={surveys} observations={observations} showAdmNumbers={showAdmNumbers} />
    </>
  );
};

const SummaryIndicator = ({ summaryIndicator, surveys, observations, showAdmNumbers }) => {
  return (
    <>
      <AdmNode node={summaryIndicator} surveys={surveys} observations={observations} showAdmNumbers={showAdmNumbers} />
      {summaryIndicator.indicators.map((indicator) => (
        <Indicator indicator={indicator} surveys={surveys} observations={observations} key={indicator.id} showAdmNumbers={showAdmNumbers} />
      ))}
    </>
  );
};

const Aspect = ({ aspect, surveys, observations, showHqim, showAdmNumbers }) => {
  return (
    <>
      <AdmNode node={aspect} surveys={surveys} observations={observations} showHqim={showHqim} showAdmNumbers={showAdmNumbers} />
      {aspect.summary_indicators.map((summaryIndicator) => (
        <SummaryIndicator
          summaryIndicator={summaryIndicator}
          surveys={surveys}
          observations={observations}
          key={summaryIndicator.id}
          showAdmNumbers={showAdmNumbers}
        />
      ))}
    </>
  );
};

const AspectCategory = ({ surveyNames, aspectCategory, combinedObservations, surveys, showHqim, groupBy, acIndex, showAdmNumbers }) => {
  const isFirstAspectCategory = acIndex === 0;
  const visibleAspects = aspectCategory.aspects.filter((aspect) => hasAtLeastOneCheckedNode(aspect));
  const groupedObservations = groupObservationsBy(groupBy, combinedObservations);

  return (
    <div>
      {aspectCategory.checked && (
        <Typography variant="h3" color="primary" className="mt-5">
          {aspectCategory.name}
        </Typography>
      )}
      {Object.entries(groupedObservations).map(([groupName, observationsInGroup], groupIndex) => (
        <Box key={groupName} sx={{ marginBottom: '1rem' }}>
          {visibleAspects.length > 0 && (
            <>
              <TableHeader surveyNames={surveyNames} groupName={groupName} groupIndex={groupIndex} groupBy={groupBy} />
              <Box sx={tableContainerStyles}>
                <Table sx={tableStyles}>
                  <TableBody>
                    {visibleAspects.map((aspect, index) => (
                      <Aspect
                        aspect={aspect}
                        surveys={surveys}
                        observations={observationsInGroup}
                        showHqim={showHqim}
                        key={index}
                        showAdmNumbers={showAdmNumbers}
                      />
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </>
          )}
        </Box>
      ))}
    </div>
  );
};

const Heatmap = ({ surveys, groupBy, admTrees, combinedObservations, showHqim, showAdmNumbers }) => {
  const surveyNames = surveys.map((survey) => survey.name);
  return (
    <div className="mt-0 care-heatmap ensure-page-break-before">
      {admTrees
        .filter((aspectCategory) => aspectCategory?.aspects?.length > 0)
        .map((aspectCategory, index) => (
          <AspectCategory
            aspectCategory={aspectCategory}
            combinedObservations={combinedObservations}
            surveys={surveys}
            surveyNames={surveyNames}
            showHqim={showHqim}
            groupBy={groupBy}
            acIndex={index}
            key={index}
            showAdmNumbers={showAdmNumbers}
          />
        ))}
    </div>
  );
};

Heatmap.propTypes = {
  surveys: PropTypes.array.isRequired,
  groupBy: PropTypes.string.isRequired,
  admTrees: PropTypes.array.isRequired,
  combinedObservations: PropTypes.array.isRequired,
};

export default Heatmap;
